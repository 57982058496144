import * as React from "react";

export default class InlineContentErrorBoundary extends React.Component<any, { error: any, errorInfo: any }> {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        });

        
    }

    render() {
        if (this.state.error) {
            return (
                <span className="error-message">{this.state.error.toString()}</span>
            );
        }

        // Normally, just render children...
        return this.props.children;
    }
}