import "./TableOfContents.scss";
import clsx from "clsx";
import "./Markdown.scss";
import _ from "lodash";
import { useEffect, useState } from "react";
import { CardHeader } from "@mui/material";

type TreeNode = {
    label: string;
    depth: number;
    parent?: TreeNode;
    children: TreeNode[];
};

const getElementId = (text: string) => text.toLowerCase().replace(/[^\w]+/g, '-');

function getDocumentLayout(markdown: string): TreeNode[] {
    const root = { label: "Root", depth: 0, children: [] } as TreeNode;

    // Match H1, H2, H3 headers only
    const regex = /^(#{1,3}) (.*)$/gm;

    // Build the heading hierarchy from the matches...
    markdown.matchAll(regex).reduce((parent, [_match, hashes, text]) => {
        const depth = hashes.length;
        const label = text.trim();
        while (depth <= parent.depth) {
            parent = parent.parent;
        }
        const newNode = { label, depth, parent, children: [] } as TreeNode;
        parent.children.push(newNode);
        return newNode;
    }, root);

    return root.children;
}

function TableOfContents({ content, contentElement } : { content: string, contentElement: Element }) {
    const layout = getDocumentLayout(content);
    const [currentSection, setCurrentSection] = useState(location.hash);

    useEffect(() => {
        const onScroll = () => {
            const currentScrollPosition = contentElement?.scrollTop;

            // Find the first anchor link below the current scroll position...
            const contentSections = [...contentElement?.querySelectorAll(`h1 > a[href^="#"], h2 > a[href^="#"], h3 > a[href^="#"]`)];
            const currentSection = contentSections.find(el => el["offsetTop"] >= currentScrollPosition);

            if (currentSection) {
                setCurrentSection(currentSection.getAttribute("href").slice(1));
            }
        };

        contentElement?.addEventListener("scroll", onScroll);
        return () => contentElement?.removeEventListener("scroll", onScroll);

    }, [content, contentElement]);

    return (
        <div className="table-of-contents">
            <CardHeader title="Contents" />
            <ul>
                {layout.map((treeNode) => <TableOfContentsElement key={treeNode.label} treeNode={treeNode} currentSection={currentSection} />)}
            </ul>
        </div>
    );
}

function TableOfContentsElement({ treeNode, currentSection }: { treeNode: TreeNode, currentSection: string }) {
    const { label, children } = treeNode;
    const targetId = getElementId(label);

    return (
        <li id={targetId}>
            <a href={`#${targetId}`} className={clsx(currentSection === targetId && "active")}>{label}</a>
            {children && children.length > 0 && <ul>{children.map(child => <TableOfContentsElement key={child.label} treeNode={child} currentSection={currentSection} />)}</ul>}
        </li>
    );
}

export { TableOfContents };